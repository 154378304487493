import React, { useEffect, useState } from 'react';

import styles from './OverallSentiment.module.css';

import VirtuOxData from '../Data/VirtuOx/dataArya';
import ConduentData from '../Data/Conduent/dataArya';
import StackBarChartH from '../stack_bar_chart_horizontal/StackBarChartH';
import MultilineChart from '../multiline_chart/MultilineChart';
import BarChartH from '../barchart_horizontal/BarChartH';

import WordCloud from '../word_cloud_chart/WordCoudChart';
import Container from '../container/Container';
import DonutChart from '../donut_chart/DonutChart';
import { useSelector } from 'react-redux';
import * as AuthSelector from '../../../store/auth/selectors';
import { loggedInUser } from "../../../store/auth/selectors";
import axios from 'axios';

const OverallSentiment = ({sentimentData}) => {
    const orgStrId = useSelector(AuthSelector.orgStrId);
    const userInfo = useSelector(loggedInUser);
    const {
        negativeSentiment,
        negativeTweetData,
        negativeWordData,
        pieChartData,
        positiveSentiment,
        positiveTweetData,
        positiveWordData,
        sentimentAnalysis,
        stackBarChartHData,
    } = orgStrId == '8EFB-D1C-B361' ? VirtuOxData : ConduentData;
    const [loading, setLoading] = useState(false); 



    return (
        <div className={styles.sentiment_container}>
            {loading ? (
                <div className={styles.predictive_body}>
                    <SkeletonChart height={250} />
                    <SkeletonChart height={250} />
                    <SkeletonChart height={250} />
                    <SkeletonChart height={250} />
                    <SkeletonChart height={250} />
                    <SkeletonChart height={250} />
                </div>
            ) : (
                <div className={styles.sentiment_body}>
                    {/* [, "var(--neutral)", "var(--negative)"]; */}
                   {['QA Manager'].includes(userInfo?.orgRole) && <Container
                        title={'Overall Sentiment Analysis'}
                        colors={[
                            '#17b26a', //positive
                            '#072b15', //neutrol
                            '#ff2b2b', //negative
                        ]}
                        legends={['Positive', 'Neutral', 'Negative']}
                        body={
                            <div className={styles.sentiment_analysis}>
                                <div className={styles.sentiment_inner}>
                                    <MultilineChart
                                        data={sentimentAnalysis}
                                        height={300}
                                        color={[
                                            '#17b26a',
                                            '#072b15',
                                            '#ff2b2b',
                                        ]}
                                        legends={[
                                            'Positive',
                                            'Neutral',
                                            'Negative',
                                        ]}
                                    />
                                </div>
                            </div>
                        }
                    />}
                    
                    <div className={styles.second_container + ' ' + "top_reso_positive"}
                    >
                        <Container
                            title='Top 5 Reasons'
                            body={
                                <div className={styles.reason_container}>
                                    <div
                                        className={
                                            styles.reason_inner_container
                                        }>
                                        <p>Positive Sentiment</p>
                                        <BarChartH
                                            paddLeft={'-10px'}
                                            textColor={true}
                                            data = {sentimentData?.["Top_Reasons"]?.Positive ? sentimentData?.["Top_Reasons"]?.Positive : []}
                                            title={'Positive Sentiment'}
                                            height={180}
                                            colors={[
                                                //  '#32D583', '#039855', '#027A48', '#054F31', '#072B15',
                                                "#2DCA72", "#072B15"
                                            ]}
                                            
                                        />
                                    </div>
                                    <div
                                        className={
                                            styles.reason_inner_container
                                        }>
                                        <p>Negative Sentiment</p>
                                        <BarChartH
                                            paddLeft={'-10px'}
                                            textColor={true}
                                            data = {sentimentData?.["Top_Reasons"]?.Negative ? sentimentData?.["Top_Reasons"]?.Negative : []}
                                            title={'Negative Sentiment'}
                                            height={180}
                                            colors={[
                                                "#F04438", "#7A271A"

                                            ]}
                                        />
                                    </div>
                                </div>
                            }
                        />
                        
                        <Container
                            title='Most Common Words'
                            body={
                                <div className={styles.reason_container}>
                                    <div
                                        className={
                                            styles.reason_inner_container
                                        }>
                                        <p>Positive Sentiment</p>
                                        <WordCloud 
                                            data={sentimentData?.["Word Cloud"]?.positive ? Object.entries(sentimentData?.["Word Cloud"]?.positive).map(([text, size]) => ({ text, size })) : []}
                                            title={'Positive Sentiment'}
                                            colorRange={[
                                                '#12B76A',
                                                '#05603A',
                                                '#AFB5D9',
                                                'rgb(102, 112, 133)',
                                            ]}
                                            height={180}
                                        />
                                    </div>
                                    <div
                                        className={
                                            styles.reason_inner_container
                                        }>
                                        <p>Negative Sentiment</p>
                                        <WordCloud
                                            data={sentimentData?.["Word Cloud"]?.negative ? Object.entries(sentimentData?.["Word Cloud"]?.negative).map(([text, size]) => ({ text, size })) : []}
                                            title={'Negative Sentiment'}
                                            colorRange={[
                                                '#B42318',
                                                '#F04438',
                                                'rgb(193, 21, 116)',
                                                'rgb(102, 112, 133)',
                                            ]}
                                            height={180}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    <div
                        className={styles.box_container}
                        style={{ padding: '0', marginTop: '10px' }}>
                        <div className={styles.title}>
                            Top 5 Common Feedback
                        </div>
                        <div className={styles.box_container_body} style={{minHeight:"300px"}}>
                            <div className={`${styles.tweets_box}`}>
                                <div className={styles.label}>
                                    Positive Sentiment
                                </div>
                                {/* positiveTweetData */}
                                {(sentimentData?.["Top Feedback"]?.Positive || [])?.map((tweet, index) => (
                                    <span key={index} className={styles.tweets}>
                                        {tweet}
                                    </span>
                                ))}
                            </div>
                            <div className={`${styles.tweets_box}`}>
                                <div className={styles.label}>
                                    Negative Sentiment
                                </div>
                                {(sentimentData?.["Top Feedback"]?.Negative || [])?.map((tweet, index) => (
                                    <span key={index} className={styles.tweets}>
                                        {tweet}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OverallSentiment;
